import { useMemo } from "react";
// import { notify } from './toastNotification';
import { GenericObject } from "../interfaces";
import { useLocation } from "react-router-dom";

export function createQuery(
  queryParams: { [key: string]: string | number | null } = {}
) {
  let queryString = "";

  if (Object.keys(queryParams).length > 0) {
    queryString = "?";

    Object.keys(queryParams).forEach((key, index) => {
      queryString +=
        index === 0
          ? `${key}=${queryParams[key]}`
          : `&${key}=${queryParams[key]}`;
    });
  }
  return queryString;
}

export function usePageQuery() {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  // console.log(search)
  return searchParams;
}

export const getInitials = (names: string[]) => {
  return names
    .filter((each) => each !== "" && typeof each === "string")
    .map((each) => each.charAt(0).toUpperCase())
    .join("");
};

export function convertToFormData(body: GenericObject = {}) {
  const appendObjectProps = (
    formData: FormData,
    payload: GenericObject,
    key_prefix: string = ""
  ) => {
    Object.keys(payload).forEach((key) => {
      const value = payload[key];
      const fieldName = !key_prefix ? key : `${key_prefix}[${key}]`;

      formData.append(fieldName, value);
    });
    return formData;
  };

  const appendArrayProps = (
    formData: FormData,
    payload: any[],
    key: string
  ) => {
    payload.forEach((item: string | Blob | object, index: number) => {
      const fieldName = `${key}[${index}]`;

      if (!!item && typeof item === "object" && !(item instanceof File)) {
        formData = appendObjectProps(formData, item, fieldName);
      } else {
        formData.append(fieldName, item);
      }
    });

    return formData;
  };

  let bodyFormData = new FormData();

  Object.keys(body).forEach((key) => {
    const value = body[key];

    if (Array.isArray(value)) {
      // Append array items to form data
      bodyFormData = appendArrayProps(bodyFormData, value, key);
    } else if (
      !!value &&
      typeof value === "object" &&
      !(value instanceof File)
    ) {
      // Append object properties to form data
      bodyFormData = appendObjectProps(bodyFormData, value, key);
    } else {
      // Append to form data
      bodyFormData.append(key, value);
    }
  });

  return bodyFormData;
}

export function getFileSize(size: number) {
  if (size > 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }

  if (size > 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }

  if (size > 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  }

  return `${size.toFixed(2)} B`;
}

export const getFileType = (fileLink: string) => {
  switch (true) {
    case fileLink.slice(-3) === "pdf":
      return "application/pdf";

    case [".jpg", "jpeg", ".png", ".gif", "webp", ".svg"].includes(
      fileLink.slice(-4)
    ):
      return "image/*";

    case [".mp4", ".avi", ".mov"].includes(fileLink.slice(-4)):
      return "video/*";

    case [".mp3", ".ogg", ".wav"].includes(fileLink.slice(-4)):
      return "audio/*";

    default:
      return "plain/text";
  }
};

type FileLink = string | File | Blob;
type ReturnAs = "file" | "blob";

export const createFileFromLink = async (
  fileLink: FileLink,
  returnAs: ReturnAs = "file"
): Promise<File | Blob | null> => {
  const isInvalidLink = !fileLink || fileLink === "null";
  if (isInvalidLink) {
    return null;
  }

  function getFileInfoFromResponse(res: Response) {
    let filename = "";

    // console.log(.next());
    // console.log({ url: res.body }); // 1 3 5 7 9

    const disposition = res.headers.get("content-disposition");

    if (disposition?.includes("attachment")) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches?.[1]) {
        filename = matches[1].replace(/['"]/g, "");
        // Sometimes the filename comes in a URI encoded format so decode it
        filename = window.decodeURIComponent(filename);
        // Sometimes the filename starts with UTF-8, remove that
        filename = filename.replace(/^UTF-8/i, "").trim();
      }
    }

    let fileType =
      typeof fileLink === "string"
        ? getFileType(fileLink as string)
        : "plain/text";

    return {
      fileName: window.decodeURIComponent(filename),
      fileType,
    };
  }

  const renderFile = async (linkOrFile: string | File) => {
    if (linkOrFile instanceof File) {
      return linkOrFile;
    } else {
      const fileUrl = linkOrFile.replaceAll(/,+/g, ".");
      let name = fileUrl.split("/").pop() || "Uploaded File";
      const options = {} ?? { method: "GET", mode: "cors" }; // no-cors, cors, same-origin

      try {
        const response = await window.fetch(fileUrl, options);
        let { fileName, fileType } = getFileInfoFromResponse(response.clone());

        if (fileName) {
          name = fileName;
        }

        if (!fileType) {
          const extension = name.split(".").pop();
          fileType = getMimeType(extension);
        }

        // console.log(...response.headers);
        const blobData = await response.blob();
        // console.log({ response: response.bodyUsed });

        return new File([blobData], window.decodeURIComponent(name), {
          type: fileType as string,
        });
      } catch (e) {
        // notify('Failed to load file', { type: "error" });
        return new File([name], window.decodeURIComponent(name), {
          type: "text/plain",
        });
      }
    }
  };

  const renderBlob = async (linkOrBlob: string | Blob): Promise<Blob> => {
    if (linkOrBlob instanceof Blob) {
      return linkOrBlob;
    }

    try {
      const fileUrl = linkOrBlob.replace(/,+/g, ".");
      let name = fileUrl.split("/").pop() || "Uploaded File";
      let extension = name.split(".").pop();
      const options = {} ?? { method: "GET", mode: "cors" }; // Ensure the server supports CORS

      const response = await fetch(fileUrl, options);
      // console.log(response);
      if (!response.ok) {
        throw new Error(`Failed to fetch resource: ${response.statusText}`);
      }

      const contentDisposition = response.headers.get("Content-Disposition");
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+?)"/);
        if (match && match[1]) {
          name = decodeURIComponent(match[1]);
        }
      }

      const blobData = await response.blob();
      const fileType =
        blobData.type || getMimeType(extension) || "application/octet-stream"; // Default type

      console.log({ fileType });
      return new Blob([blobData], { type: fileType });
    } catch (error) {
      console.error("Error rendering Blob:", error);
      return new Blob(); // Return an empty Blob on error
    }
  };

  switch (returnAs) {
    case "blob":
      return renderBlob(fileLink as string | Blob);

    case "file":
    default:
      return renderFile(fileLink as string | File);
  }
};

export const isPromise = (object: any | Promise<any>) => {
  return object instanceof Promise;
};

export const convertToStandardFormat = (n: string | number) => {
  // console.log(n);
  n = `${n}`.replace(/,/g, "");
  const isValidDigits = n.match(/^[0-9.]+$/gi) !== null;

  if (isValidDigits) {
    n = Math.round(Number(n));
    const numberOfDigits = `${n}`.length;
    // console.log("numberOfDigits", numberOfDigits);

    let newNumber: string | number = n;

    switch (true) {
      // 10,000 - 99,999 (Hundreds of Thousands)
      case numberOfDigits > 4 && numberOfDigits <= 5:
        newNumber = `${Number(n / 1e3).toFixed(2)} K`;
        n = newNumber;
        break;

      // 100,000 - 999,999 (Hundreds of Thousands)
      case numberOfDigits > 5 && numberOfDigits <= 6:
        newNumber = `${Number(n / 1e3).toFixed(2)} K`;
        n = newNumber;
        break;

      // 1,000,000 - 999,999,999 (Million)
      case numberOfDigits > 6 && numberOfDigits <= 9:
        newNumber = `${Number(n / 1e6).toFixed(2)} M`;
        n = newNumber;
        break;

      // 1,000,000,000 - 999,999,999,999 (Billion)
      case numberOfDigits > 9 && numberOfDigits <= 12:
        newNumber = `${Number(n / 1e9).toFixed(2)} B`;
        n = newNumber;
        break;

      // 1,000,000,000,000 - 999,999,999,999,999 (Trillion)
      case numberOfDigits > 12 && numberOfDigits <= 15:
        newNumber = `${Number(n / 1e12).toFixed(2)} T`;
        n = newNumber;
        break;

      // 1,000,000,000,000,000 - 999,999,999,999,999,999 (Quadrillion)
      case numberOfDigits > 15 && numberOfDigits <= 18:
        newNumber = `${Number(n / 1e15).toFixed(2)} aa`;
        n = newNumber;
        break;

      default:
        break;
    }

    // console.log(newNumber);
  }

  return n;
};

export const formatNumberTo2Decimal = (n: string | number) => {
  let number = typeof n === "string" ? Number(n) : n;
  return Number(number?.toFixed(2));
};

export function numberWithCommas(number: number) {
  return number?.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });
}

// Function to get the value at a given path
export function getValueAtPath(obj: Object, path: string) {
  const pathArray = path.split(/[.[\]]/).filter(Boolean);
  return pathArray.reduce((acc: any, key) => {
    if (acc && acc[key] !== undefined) {
      return acc[key];
    }
    return undefined;
  }, obj);
}

export const mergeClassNames = (...classNames: (string | string[])[]) => {
  const finalResult = classNames.reduce<string[]>(
    (combined: string[], nextClass: string | string[]) => {
      const uniqueClasses: string[] = [];
      let a: string[];

      if (typeof nextClass === "string") {
        nextClass = nextClass.split(/\s+/gi);
      }

      a = combined;

      nextClass.forEach((stringClass: string) => {
        if (!a.includes(stringClass)) {
          uniqueClasses.push(stringClass);
        }
      });

      return a.concat(uniqueClasses);
    },
    [] as string[]
  );
  return finalResult.join(" ");
};

export const maskSection = (
  text: string,
  ignoreFromIndex = 0,
  forwards = true
) => {
  // text = "temiloluwa.ogunbanjo+234@tradebuza.com";
  // ignoreFromIndex = text.indexOf('@');
  // forwards = false;
  const textLength = text.length;

  const ignoredTextSection = text.slice(
    forwards ? ignoreFromIndex : 0,
    forwards ? textLength : ignoreFromIndex
  );
  const mainTextSection = text.replace(ignoredTextSection, "");

  const lengthOfMainText = mainTextSection.length;
  let prefixLength = Math.max(Math.round(lengthOfMainText * 0.25), 3); // Not less than 3
  let maskLength = Math.min(Math.round(lengthOfMainText * 0.5), 8); // Not greater than 6
  maskLength = Math.min(maskLength, lengthOfMainText - prefixLength);

  let suffixLength = Math.max(lengthOfMainText - prefixLength - maskLength, 0); // Not less than 0
  suffixLength = Math.min(suffixLength, prefixLength);

  const mask = "*".repeat(maskLength);

  const prefix = mainTextSection?.substring(0, prefixLength) ?? "--";
  const suffix =
    mainTextSection?.substring(lengthOfMainText - suffixLength) ?? "--";
  const maskedString = `${prefix}${mask}${suffix}`;

  // console.log({
  //   textLength,
  //   lengthOfMainText,
  //   prefixLength,
  //   suffixLength,
  //   maskLength,
  //   forwards,
  // });
  return forwards
    ? maskedString + ignoredTextSection
    : ignoredTextSection + maskedString;
};

export function toHex(str: string) {
  let hex = "";
  for (let i = 0; i < str.length; i++) {
    hex += str.charCodeAt(i).toString(16).padStart(2, "0");
  }
  return hex;
}

export const getMimeType = (extension: string = "txt") => {
  const mimeTypes: GenericObject = {
    pdf: "application/pdf",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    txt: "text/plain",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // Add more as needed
  };

  // Remove leading dot if present
  const cleanExtension = extension.startsWith(".")
    ? extension.slice(1)
    : extension;
  return mimeTypes[cleanExtension.toLowerCase()] || "application/octet-stream"; // Fallback for unknown types
};

export const viewFile = (base64Data: string | Blob, extension: string) => {
  let blob: Blob;
  const fileType = getMimeType(extension);

  if (typeof base64Data === "string") {
    const byteCharacters = atob(base64Data);
    const byteNumbers = Array.from(byteCharacters, (char) =>
      char.charCodeAt(0)
    );
    const byteArray = new Uint8Array(byteNumbers);
    blob = new Blob([byteArray], { type: fileType });
  } else {
    blob = base64Data;
  }

  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl);
};

export const downloadFile = (
  base64Data: string | Blob,
  extension: string,
  fileName: string
) => {
  const fileType = getMimeType(extension);
  let blob: Blob;

  if (typeof base64Data === "string") {
    const byteCharacters = atob(base64Data);
    const byteNumbers = Array.from(byteCharacters, (char) =>
      char.charCodeAt(0)
    );
    const byteArray = new Uint8Array(byteNumbers);
    blob = new Blob([byteArray], { type: fileType });
  } else {
    blob = base64Data;
  }

  console.log(blob, extension, fileName, fileType);

  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(blobUrl); // Clean up the URL
};
